import React, {useEffect, useState} from "react"
import "./style.scss";
import logo from "../../images/logo.png"

const Contact = ({open, setOpenContact}) => {

    return (
        <>
            <div className={(open) ? 'popup-contact visible' : 'popup-contact'}>
                <div>
                    <img src={logo} alt="logo" width="300"/>
                    <div className="close-modal" onClick={() => setOpenContact(false)}><span></span><span></span></div>
                </div>
                <div className="popup-address">
                    <div>
                        <h4>AGENCE PARIS</h4>
                        <p>166 rue Saint-Honoré<br/>
                            Paris 1er arr.<br/>
                            06 66 84 56 46 / 06 37 59 51 31<br/>
                            kevin@dorenavant-studio.fr / olivier@dorenavant-studio.fr</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact
