import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header"
import Footer from "../footer"

import "./style.scss"
import Contact from "../contact";
import SEO from "../seo";

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    const [openContact, setOpenContact] = useState(false);

    return (
    <>
      <SEO title="Dorénavant" />
      <Header siteTitle={data.site.siteMetadata.title} className={className} openContact={openContact} setOpenContact={setOpenContact}/>
      <div>
        <main className={className}>{children}</main>
      </div>
      <Footer openContact={openContact} setOpenContact={setOpenContact}/>
      <Contact open={openContact} setOpenContact={setOpenContact}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
