import React from "react"
import "./style.scss";
import logo from "../../images/logo_sm_black.png"

const Footer = ({setOpenContact}) => (
  <footer>
    <div>
      <div>
        <img src={logo} alt="logo" width="66px" height="66px"/>
        <div>
          <span onClick={() => {
            setOpenContact(true)
          }} style={{cursor: "pointer"}}>contact</span>
        </div>
      </div>
      <div>
        <div>
          <h6>AGENCE PARIS</h6>
          <span>166 rue Saint-Honoré</span>
          <span>Paris 1er arr.</span>
          <span><a href="mailto:kevin@dorenavant-studio.fr" target="_blank" rel="noreferrer" style={{color: "#A89D89", textDecoration: "none"}}>kevin@dorenavant-studio.fr</a> / <a href="mailto:olivier@dorenavant-studio.fr" target="_blank" rel="noreferrer" style={{color: "#A89D89", textDecoration: "none"}}>olivier@dorenavant-studio.fr</a></span>
        </div>
      </div>
    </div>
    <p>Dorénavant studio 2020 - mentions légales</p>
  </footer>
)

export default Footer
