import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import "./style.scss";
import logo from "../../images/logo.png"
import smallLogo from "../../images/logo_sm_black.png"

const Header = ({ className, openContact, setOpenContact }) => {
  const [openNav, setOpenNav] = useState(false);
  return (
    <>
      <header className={`sticky-header ${className}`}>
        <div>
          <Link to="/?s=0">
            <img src={logo} alt="logo"/>
            <img src={smallLogo} alt="logo"/>
          </Link>
          <div className="hamburger" onClick={() => setOpenNav(true)} role="nav">
            <span/>
            <span/>
            <span/>
          </div>
          <nav className={openNav ? "open": ""}>
            <div className="close" onClick={() => setOpenNav(false)}>
              <span/>
              <span/>
            </div>
            <ul>
              <li>
                <Link to="https://www.instagram.com/dorenavant_studio/" target="_blank">
                  <span className="underline" />INSTAGRAM
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/duo"> <span className="underline" />Duo</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/etat-d-esprit"> <span className="underline" />état d'esprit</Link>*/}
              {/*</li>*/}
              <li>
                <Link to="/projets"> <span className="underline" />Projets</Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/projets/hotels"> <span className="underline" />Hôtels</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/projets/restaurants"> <span className="underline" />Restaurants</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/projets/residentiels"> <span className="underline" />Résidentiels</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/mobilier">Mobilier</Link>*/}
              {/*</li>*/}
              <li>
                <button onClick={() => {
                  setOpenNav(false);
                  setOpenContact(true)
                }}> <span className="underline" style={{ top: "-12px"}}/>Contact</button>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Dorénavant`,
}

export default Header
